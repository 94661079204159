var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "설비 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && !_vm.updateMode,
                            label: "설비관리번호",
                            name: "equipmentNo",
                          },
                          model: {
                            value: _vm.data.equipmentNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentNo", $$v)
                            },
                            expression: "data.equipmentNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "설비명",
                            name: "equipmentName",
                          },
                          model: {
                            value: _vm.data.equipmentName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentName", $$v)
                            },
                            expression: "data.equipmentName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-equip-class", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "설비유형",
                            name: "equipmentTypeCd",
                          },
                          model: {
                            value: _vm.data.equipmentTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentTypeCd", $$v)
                            },
                            expression: "data.equipmentTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && !_vm.updateMode,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            type: "edit",
                            required: true,
                            label: "관리부서",
                            name: "managementDepts",
                          },
                          model: {
                            value: _vm.data.managementDepts,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "managementDepts", $$v)
                            },
                            expression: "data.managementDepts",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            editable: _vm.editable,
                            label: "단위공정",
                            multiple: "multiple",
                            required: true,
                            name: "subProcessCd",
                          },
                          model: {
                            value: _vm.data.subProcessCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "subProcessCd", $$v)
                            },
                            expression: "data.subProcessCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "수량",
                            name: "amount",
                            type: "number",
                          },
                          model: {
                            value: _vm.data.amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "amount", $$v)
                            },
                            expression: "data.amount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "설치관련 정보", topClass: "topcolor-blue" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설비위치",
                            name: "equipmentLocation",
                          },
                          model: {
                            value: _vm.data.equipmentLocation,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentLocation", $$v)
                            },
                            expression: "data.equipmentLocation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: { label: "설치일자", name: "installDate" },
                          model: {
                            value: _vm.data.installDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "installDate", $$v)
                            },
                            expression: "data.installDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설치업체",
                            name: "installVendor",
                          },
                          model: {
                            value: _vm.data.installVendor,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "installVendor", $$v)
                            },
                            expression: "data.installVendor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: { label: "가동일자", name: "opertationDate" },
                          model: {
                            value: _vm.data.opertationDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "opertationDate", $$v)
                            },
                            expression: "data.opertationDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "설치상태 정보", topClass: "topcolor-blue" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "검사합격번호",
                            name: "checkPassNo",
                          },
                          model: {
                            value: _vm.data.checkPassNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "checkPassNo", $$v)
                            },
                            expression: "data.checkPassNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: { label: "합격일자", name: "checkPassDate" },
                          model: {
                            value: _vm.data.checkPassDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "checkPassDate", $$v)
                            },
                            expression: "data.checkPassDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: { label: "폐기일자", name: "discardDate" },
                          model: {
                            value: _vm.data.discardDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "discardDate", $$v)
                            },
                            expression: "data.discardDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "점검관련 정보", topClass: "topcolor-blue" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.flagItems,
                            label: "법정설비여부",
                            name: "lawEquipmentFlag",
                          },
                          model: {
                            value: _vm.data.lawEquipmentFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "lawEquipmentFlag", $$v)
                            },
                            expression: "data.lawEquipmentFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "inspectionCycleCd",
                            label: "법정점검주기",
                          },
                          model: {
                            value: _vm.data.inspectionCycleCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "inspectionCycleCd", $$v)
                            },
                            expression: "data.inspectionCycleCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: false,
                            codeGroupCd: "MDM_EQUIP_GRADE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "equipGradeCd",
                            label: "설비등급",
                          },
                          model: {
                            value: _vm.data.equipGradeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipGradeCd", $$v)
                            },
                            expression: "data.equipGradeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "selfCycleCd",
                            label: "자체점검주기",
                          },
                          model: {
                            value: _vm.data.selfCycleCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "selfCycleCd", $$v)
                            },
                            expression: "data.selfCycleCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "pmCycleCd",
                            label: "예방정비주기",
                          },
                          model: {
                            value: _vm.data.pmCycleCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "pmCycleCd", $$v)
                            },
                            expression: "data.pmCycleCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.flagItems,
                            label: "예방정비여부",
                            name: "pmFlag",
                          },
                          model: {
                            value: _vm.data.pmFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "pmFlag", $$v)
                            },
                            expression: "data.pmFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.flagItems,
                            label: "예지정비여부",
                            name: "predictiveFlag",
                          },
                          model: {
                            value: _vm.data.predictiveFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "predictiveFlag", $$v)
                            },
                            expression: "data.predictiveFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.flagItems,
                            label: "고장정비여부",
                            name: "cmFlag",
                          },
                          model: {
                            value: _vm.data.cmFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "cmFlag", $$v)
                            },
                            expression: "data.cmFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "법규관련 정보", topClass: "topcolor-blue" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-multi-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "REGULATION_BILL_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "관련 법규",
                            name: "relatedLaws",
                            maxValues: "3",
                          },
                          model: {
                            value: _vm.data.relatedLaws,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "relatedLaws", $$v)
                            },
                            expression: "data.relatedLaws",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.psmFlagItems,
                            label: "PSM 대상 여부",
                            name: "psmFlag",
                          },
                          model: {
                            value: _vm.data.psmFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "psmFlag", $$v)
                            },
                            expression: "data.psmFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.flagItems,
                            label: "유해위험기계기구 여부",
                            name: "hazardousFlag",
                          },
                          model: {
                            value: _vm.data.hazardousFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "hazardousFlag", $$v)
                            },
                            expression: "data.hazardousFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "폐기 관련", topClass: "topcolor-blue" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.discardFlagItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "discardFlag",
                            label: "폐기여부",
                          },
                          model: {
                            value: _vm.data.discardFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "discardFlag", $$v)
                            },
                            expression: "data.discardFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable:
                              _vm.editable && _vm.data.discardFlag == "Y",
                            label: "폐기일자",
                            name: "discardDate",
                          },
                          model: {
                            value: _vm.data.discardDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "discardDate", $$v)
                            },
                            expression: "data.discardDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }